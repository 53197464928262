import parseISO from 'date-fns/esm/parseISO';
import { List } from 'purify-ts/List';
import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { Sender } from 'xstate';
import Alert from '../../../design-system/components/Alert';
import Container, { Cell, Subgrid } from '../../../design-system/components/Container';
import InputGroup, {
  InputDob,
  InputPhone,
  InputSelect,
  useInputDobState,
  useInputGroupState,
  useInputPhoneState,
  useInputSelectState,
} from '../../../design-system/components/InputGroup';
import Tag, { Wrapper as TagWrapper } from '../../../design-system/components/Tag';
import { H2, H3, P } from '../../../design-system/components/Text';
import { Sticky, Theme } from '../../../design-system/helpers/components';
import { hooks } from '../../../design-system/helpers/mixins';
import { CatalogContext, CatalogEvent } from '../../../fsm/catalog/catalogMachine';
import { passengerInfoSchema, reserverInfoSchema, vehicleInfoSchema } from '../../../fsm/catalog/guards';
import * as API from '../../../fsm/types';
import { PassengerGender, TripTagTypes } from '../../../fsm/types';
import {
  getAccommodations,
  getAccommodationsAsProducts,
  inGenderSpecific,
} from '../../../fsm/utils/accommodationUtils';
import { getSelected, getSelectedSailings } from '../../../fsm/utils/sailingUtils';
import { TreeData } from '../../../storyblok';
import { formatString } from '../../../utils/formats';
import Notification from '../../common/Notification';
import { createTags } from '../../payment/confirm/PassengerInfo';
import CatalogFooter, { CatalogFooterPassedProps } from '../CatalogFooter';
import { setInitialValueBasedOnLanguage } from '../../../design-system/components/InputGroup/useInputGroupState';
import { LanguageContext } from '../../../Language.context';
import { isTravelDocumentNumberFieldVisible } from '../../../fsm/utils/passengerUtils';

type PassengerInfoProps = {
  context: CatalogContext;
  send: Sender<CatalogEvent>;
};

interface CountryList {
  readonly label?: string;
  readonly list: TreeData[];
}

interface FormProps {
  readonly send: Sender<CatalogEvent>;
  readonly forceValidation: boolean;
  readonly errors: { [name: string]: string };
}

interface PassengerFormProps extends FormProps {
  readonly passenger: API.ExtendedPassenger;
  readonly countryLists: CountryList[];
  readonly initialCountry?: string;
  readonly privacyError?: string;
  readonly starclub?: boolean;
  readonly requireGender?: PassengerGender[];
  readonly showTravelDocumentNumberField?: boolean;
  readonly tags?: {
    label: TripTagTypes;
    legs: number[];
    cabin: string | undefined;
  }[];
  readonly firstDepartureDateTime: Date;
  readonly selectedOnboards?: API.ExtendedOnboard[] | API.PriceError;
  readonly selectedSailings?: any;
}

interface VehicleFormProps extends FormProps {
  readonly vehicle: API.ExtendedVehicle;
  readonly shipNames: (string | null | undefined)[];
}

/**
 * FIXME: United Kingdom has three silly territories which make development harder
 * because Isle of Man, Jersey, and Guernsey share the same country call code (+44).
 * For now, these are skipped when assigning the initial phone mask, but eventually
 * we should implement a better fix to this edge case.
 */
const isBritishCrownDependency = (country: string): boolean => ['IM', 'JE', 'GG'].includes(country);

const PassengerForm: FunctionComponent<PassengerFormProps> = ({
  passenger,
  send,
  countryLists,
  initialCountry,
  forceValidation,
  starclub,
  errors,
  requireGender,
  showTravelDocumentNumberField,
  tags,
  firstDepartureDateTime,
  selectedOnboards,
  selectedSailings,
}) => {
  const sbPassengers = hooks.useStoryblokComponents('products/passengers');
  const [sbForm, formRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.passenger_info_form',
  });
  const [sbGenderSelect] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.gender_select',
  });
  const [sbNeedForCareSelect] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.need_for_care_select',
  });
  const sbSummaryTags = hooks.useStoryblokDatasource('summary-tags');

  const onFail = (key: string) => {
    // set travelDocumentNumber to 'undefined' for schema validation to flag it as incorrect
    send({
      type: 'ADD_PASSENGER_INFO',
      data: { id: passenger.id, info: { [key]: key === 'travelDocumentNumber' ? undefined : '' } },
    });
  };

  const onReady = (key: string, val: string) => {
    send({ type: 'ADD_PASSENGER_INFO', data: { id: passenger.id, info: { [key]: val } } });
  };

  const { language } = useContext(LanguageContext);

  //Load initial states to machine
  useEffect(() => {
    if (initialCountry && !passenger.info?.nationality) {
      send({
        type: 'ADD_PASSENGER_INFO',
        data: {
          id: passenger.id,
          info: {
            nationality: setInitialValueBasedOnLanguage(language, initialCountry),
            travelDocumentNumber: passenger.info?.travelDocumentNumber ?? '',
          },
        },
      });
    }
  }, [initialCountry, passenger, passenger.id, send]);

  const validationSchema = useMemo(() => passengerInfoSchema(firstDepartureDateTime), [firstDepartureDateTime]);

  const input = {
    firstname: {
      ...useInputGroupState({
        label: sbForm?.content.first_name,
        errorMsg: errors[API.InfoError.FIRSTNAME] || 'Invalid firstname',
        htmlFor: `${passenger.id}-firstname`,
        type: passenger.type as API.PassengerType,
        validationSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'firstname',
        initial: passenger.info?.firstname ? passenger.info?.firstname : undefined,
      }),
    },
    lastname: {
      ...useInputGroupState({
        label: sbForm?.content.last_name,
        errorMsg: errors[API.InfoError.LASTNAME] || 'Invalid lastname',
        htmlFor: `${passenger.id}-lastname`,
        type: passenger.type as API.PassengerType,
        validationSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'lastname',
        initial: passenger.info?.lastname ? passenger.info.lastname : undefined,
      }),
    },
    birth: {
      ...useInputDobState({
        label: sbForm?.content.date_of_birth,
        dayLabel: sbForm?.content.date_of_birth_day,
        monthLabel: sbForm?.content.date_of_birth_month,
        yearLabel: sbForm?.content.date_of_birth_year,
        errorMsg: errors[API.InfoError.BIRTH] || 'Invalid birthday',
        htmlFor: `${passenger.id}-birth`,
        type: passenger.type as API.PassengerType,
        validationSchema,
        onReady,
        onFail,
        forceValidation,
        firstDepartureDateTime,
        key: 'birth',
        initial: passenger.info?.birth ? passenger.info.birth : undefined,
      }),
    },
    starclub: {
      ...useInputGroupState({
        mask: '###############',
        formatChars: {
          '#': '[A-Za-z0-9]',
        },
        label: sbForm?.content.star_club,
        errorMsg: errors[API.InfoError.STARCLUB] || 'Invalid membership number',
        htmlFor: `${passenger.id}-starclub`,
        type: passenger.type as API.PassengerType,
        validationSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'starclub',
        initial: passenger.info?.starclub ? passenger.info.starclub : undefined,
        optionalValidationProps: {
          requireReserver: starclub ? passenger.reserver : false,
        },
      }),
    },
    gender: {
      ...useInputSelectState({
        label: sbForm?.content.gender,
        errorMsg:
          requireGender?.includes(PassengerGender.FEMALE) && requireGender?.includes(PassengerGender.MALE)
            ? errors[API.InfoError.BOTH_GENDERS]
            : requireGender?.includes(PassengerGender.MALE)
            ? errors[API.InfoError.MALE_GENDER]
            : requireGender?.includes(PassengerGender.FEMALE)
            ? errors[API.InfoError.FEMALE_GENDER]
            : errors[API.InfoError.GENDER] || 'Invalid gender',
        htmlFor: `${passenger.id}-gender`,
        type: passenger.type as API.PassengerType,
        optionalValidationProps: requireGender ? { requireGender: requireGender } : {},
        validationSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'gender',
        initial: passenger.info?.gender ? passenger.info.gender : undefined,
      }),
    },
    nationality: {
      ...useInputSelectState({
        label: sbForm?.content.nationality,
        errorMsg: errors[API.InfoError.NATIONALITY] || 'Invalid nationality',
        htmlFor: `${passenger.id}-nationality`,
        type: passenger.type as API.PassengerType,
        validationSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'nationality',
        initial: passenger.info?.nationality ? passenger.info.nationality : initialCountry,
      }),
    },
    specialNeeds: {
      ...useInputSelectState({
        label: sbForm?.content.need_for_care,
        htmlFor: `${passenger.id}-specialNeeds`,
        type: passenger.type as API.PassengerType,
        onReady,
        onFail,
        forceValidation,
        key: 'specialNeeds',
        initial: passenger.info?.specialNeeds ? passenger.info.specialNeeds : '',
      }),
    },
    travelDocumentNumber: {
      ...useInputGroupState({
        label: sbForm?.content.travel_document_number,
        errorMsg: errors[API.InfoError.TRAVEL_DOCUMENT_NUMBER] || 'Invalid travel document number',
        htmlFor: `${passenger.id}-travelDocumentNumber`,
        type: passenger.type as API.PassengerType,
        optionalValidationProps: showTravelDocumentNumberField
          ? { showTravelDocumentNumberField: showTravelDocumentNumberField }
          : {},
        validationSchema,
        onReady,
        onFail,
        forceValidation,
        isTravelDocumentNumberInput: true,
        key: 'travelDocumentNumber',
        initial: passenger.info?.travelDocumentNumber ? passenger.info?.travelDocumentNumber : '',
      }),
    },
    // travelDocumentType: {
    //   ...useInputSelectState({
    //     label: 'Travel document type', // TODO: add to Storyblok
    //     errorMsg: 'Invalid travel document type',
    //     htmlFor: `${passenger.id}-travelDocumentType`,
    //     type: passenger.type as API.PassengerType,
    //     validationSchema, // TODO: Add type validation rules to the schema
    //     onReady,
    //     onFail,
    //     forceValidation,
    //     key: 'travelDocumentType',
    //     initial: passenger.info?.travelDocumentType ? passenger.info.travelDocumentType : undefined, TODO: add to PassengerInfo type
    //   }),
    // },
    // travelDocumentExpiryDate: {
    //   ...useInputGroupState({
    //     label: 'Travel document expiry date', // TODO: add to Storyblok
    //     errorMsg: 'Invalid travel document expiry date',
    //     htmlFor: `${passenger.id}-travelDocumentExpiryDate`,
    //     type: passenger.type as API.PassengerType,
    //     validationSchema, // TODO: Add date validation rules to the schema
    //     onReady,
    //     onFail,
    //     forceValidation,
    //     key: 'travelDocumentExpiryDate',
    //     initial: passenger.info?.travelDocumentExpiryDate ? passenger.info.travelDocumentExpiryDate : undefined, // TODO: add to PassengerInfo type
    //   }),
    // },
  };

  const name = [input.firstname.value, input.lastname.value].filter((n) => n.length > 0).join(' ');

  const selectedOnboardsLeg1 =
    selectedOnboards && Array.isArray(selectedOnboards)
      ? selectedOnboards.filter((onboard) => onboard.legs.includes(1))
      : undefined;
  const selectedOnboardsLeg2 =
    selectedOnboards && Array.isArray(selectedOnboards)
      ? selectedOnboards.filter((onboard) => onboard.legs.includes(2))
      : undefined;
  const sbPorts = hooks.useStoryblokDatasource('ports');

  const passengerStr = (type: API.PassengerType | API.PetType) =>
    [sbPassengers[type]?.content.title, sbPassengers[type]?.content.description].join(' ');

  return (
    <Subgrid variant="dense" ref={formRef}>
      <Cell gridSpan={2}>
        <H3>{name.length > 0 ? name : passengerStr(passenger.type)}</H3>
        <TagWrapper>
          {tags &&
            tags.map((tag, index) => {
              // TODO: Add Multileg and cruise tags
              const typeTag = tag.label;

              return <Tag addition={`${sbSummaryTags[typeTag] || ''}: ${tag.cabin}`} key={index} />;
            })}
        </TagWrapper>
        {selectedOnboardsLeg1 && selectedOnboardsLeg1.length > 0 && (
          <>
            <P>
              {sbPorts[selectedSailings[0].sailing.departurePort]}-{sbPorts[selectedSailings[0].sailing.arrivalPort]}
            </P>
            <TagWrapper>
              {(selectedOnboardsLeg1?.length ?? 0) > 0 &&
                Array.isArray(selectedOnboardsLeg1) &&
                selectedOnboardsLeg1?.map((onboard: API.ExtendedOnboard, index: number | null | undefined) => {
                  const [sbOnboard] = hooks.useStoryblokComponent<HTMLLIElement>({
                    path: onboard.code || '',
                  });
                  const sbMeals = hooks.useStoryblokDatasource('meal-types');

                  return (
                    <Tag
                      addition={`${sbOnboard?.content.title || sbMeals[onboard.code] || onboard.code}`}
                      key={index}
                    />
                  );
                })}
            </TagWrapper>
          </>
        )}
        {selectedOnboardsLeg2 && selectedOnboardsLeg2.length > 0 && (
          <>
            <P>
              {sbPorts[selectedSailings[1].sailing.departurePort]}-{sbPorts[selectedSailings[1].sailing.arrivalPort]}
            </P>
            <TagWrapper>
              {(selectedOnboardsLeg2?.length ?? 0) > 0 &&
                Array.isArray(selectedOnboardsLeg2) &&
                selectedOnboardsLeg2?.map((onboard: API.ExtendedOnboard, index: number | null | undefined) => {
                  const [sbOnboard] = hooks.useStoryblokComponent<HTMLLIElement>({
                    path: onboard.code || '',
                  });
                  const sbMeals = hooks.useStoryblokDatasource('meal-types');

                  return (
                    <Tag
                      addition={`${sbOnboard?.content.title || sbMeals[onboard.code] || onboard.code}`}
                      key={index}
                    />
                  );
                })}
            </TagWrapper>
          </>
        )}
        {Array.isArray(selectedOnboards) && <br />}
      </Cell>

      <InputGroup {...input.firstname} />
      <InputGroup {...input.lastname} />

      <InputDob gridSpan={2} {...input.birth} />

      <InputSelect {...input.gender}>
        <option value="" disabled>
          {sbGenderSelect?.content.placeholder}
        </option>
        {sbGenderSelect?.content.choices.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </InputSelect>
      <InputSelect {...input.nationality}>
        {initialCountry &&
          countryLists.map(({ label, list }, i) => (
            <optgroup key={i} {...{ label }}>
              {list.map(({ content }) => (
                <option key={content.value} value={content.value}>
                  {content.label}
                </option>
              ))}
            </optgroup>
          ))}
      </InputSelect>

      {starclub ? <InputGroup gridSpan={2} {...input.starclub} /> : null}

      {/* {showTravelDocumentNumberField && (
        <>
          <InputSelect {...input.travelDocumentType}>
            <option value="" disabled>
              Please select
            </option>
            {[
              { value: 'ID CARD', label: 'ID Card' }, // TODO: Set this values in Storyblok
              { value: 'PASSPORT', label: 'Passport' },
            ].map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </InputSelect>
          <InputGroup {...input.travelDocumentExpiryDate} />
        </>
      )} */}

      {showTravelDocumentNumberField && <InputGroup gridSpan={2} {...input.travelDocumentNumber} />}

      <InputSelect gridSpan={2} {...input.specialNeeds}>
        {sbNeedForCareSelect?.content.choices.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </InputSelect>
    </Subgrid>
  );
};

const InfoForm: FunctionComponent<PassengerFormProps> = ({
  passenger,
  send,
  countryLists,
  initialCountry,
  forceValidation,
  errors,
}) => {
  const [sbForm, formRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.contact_info_form',
  });
  const initialReserver = (passenger as API.ExtendedPassenger).info?.reserverInfo;

  const phoneMasks = countryLists.map(({ label, list }) => ({
    label,
    list:
      list &&
      list.map(({ content }) => ({
        label: `${content.label} (+${content.phone})`,
        value: content.value,
        flag: content.no_flag ? null : content.flag || content.value,
        mask: `+${content.phone}###############`,
        countryCallCode: `+${content.phone}`,
      })),
  }));

  const { language } = useContext(LanguageContext);

  //Load initial states to machine
  useEffect(() => {
    if (initialCountry && !initialReserver?.country) {
      send({
        type: 'ADD_PASSENGER_INFO',
        data: {
          id: passenger.id,
          info: {
            reserverInfo: { country: setInitialValueBasedOnLanguage(language, initialCountry) },
          },
        },
      });
    }
  }, [initialCountry, initialReserver, passenger.id, send]);

  const onFail = (key: string) => {
    send({
      type: 'ADD_PASSENGER_INFO',
      data: { id: passenger.id, info: { reserverInfo: { [key]: '' } } },
    });
  };

  const onReady = (key: string, val: string) =>
    send({
      type: 'ADD_PASSENGER_INFO',
      data: { id: passenger.id, info: { reserverInfo: { [key]: val } } },
    });

  const input = {
    address: {
      ...useInputGroupState({
        label: sbForm?.content.address,
        errorMsg: errors[API.InfoError.ADDRESS] || 'Invalid address',
        htmlFor: `${passenger.id}-address`,
        validationSchema: reserverInfoSchema,
        type: passenger.type as API.PassengerType,
        onReady,
        onFail,
        forceValidation,
        key: 'address',
        initial: initialReserver?.address ? initialReserver.address : undefined,
      }),
    },
    city: {
      ...useInputGroupState({
        label: sbForm?.content.city,
        errorMsg: errors[API.InfoError.CITY] || 'Invalid city',
        htmlFor: `${passenger.id}-city`,
        type: passenger.type as API.PassengerType,
        validationSchema: reserverInfoSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'city',
        initial: initialReserver?.city ? initialReserver.city : undefined,
      }),
    },
    country: {
      ...useInputSelectState({
        label: sbForm?.content.country,
        errorMsg: errors[API.InfoError.COUNTRY] || 'Invalid country',
        htmlFor: `${passenger.id}-nationality`,
        type: passenger.type as API.PassengerType,
        validationSchema: reserverInfoSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'country',
        initial: initialReserver?.country ? initialReserver.country : initialCountry,
      }),
    },
    phone: {
      ...useInputPhoneState({
        label: sbForm?.content.phone,
        errorMsg: errors[API.InfoError.PHONE] || 'Invalid phone number',
        htmlFor: `${passenger.id}-phone`,
        type: passenger.type as API.PassengerType,
        validationSchema: reserverInfoSchema,
        onReady: (key, val, mask) =>
          send({
            type: 'ADD_PASSENGER_INFO',
            data: {
              id: passenger.id,
              info: { reserverInfo: { countryCallCode: mask, [key]: val } },
            },
          }),
        onFail,
        forceValidation,
        key: 'phone',
        masks: phoneMasks,
        initialMask: initialReserver?.countryCallCode
          ? phoneMasks
              .map((mask) => mask.list)
              .flat()
              .find(
                (phone) =>
                  !isBritishCrownDependency(phone?.value) && phone?.countryCallCode === initialReserver.countryCallCode
              )?.value
          : initialCountry,
        maskLabel: 'Select country',
        initial: initialReserver?.phone ? initialReserver.phone : undefined,
      }),
    },
    email: {
      ...useInputGroupState({
        label: sbForm?.content.email,
        errorMsg: errors[API.InfoError.EMAIL] || 'Invalid e-mail address',
        htmlFor: `${passenger.id}-email`,
        type: passenger.type as API.PassengerType,
        validationSchema: reserverInfoSchema,
        onReady,
        onFail,
        forceValidation,
        key: 'email',
        initial: initialReserver?.email ? initialReserver.email : undefined,
      }),
    },
  };

  const postalCode = {
    ...useInputGroupState({
      mask: '###############',
      formatChars: {
        '#': '[A-Za-z0-9]',
      },
      label: sbForm?.content.postal_code,
      errorMsg: errors[API.InfoError.POSTALCODE] || 'Invalid postal code',
      htmlFor: `${passenger.id}-postalCode`,
      type: passenger.type as API.PassengerType,
      optionalValidationProps: { country: input.country.value },
      validationSchema: reserverInfoSchema,
      onReady,
      onFail,
      forceValidation,
      key: 'postalCode',
      initial: initialReserver?.postalCode ? initialReserver.postalCode : undefined,
    }),
  };

  return (
    <Subgrid variant="dense" ref={formRef}>
      <InputGroup gridSpan={2} {...input.address} />

      <InputGroup {...postalCode} />
      <InputGroup {...input.city} />

      <InputSelect gridSpan={2} {...input.country}>
        {initialCountry &&
          countryLists.map(({ label, list }, i) => (
            <optgroup key={i} {...{ label }}>
              {list.map(({ content }) => (
                <option key={content.value} value={content.value}>
                  {content.label}
                </option>
              ))}
            </optgroup>
          ))}
      </InputSelect>

      <InputPhone gridSpan={2} {...input.phone} />
      <InputGroup gridSpan={2} {...input.email} />
    </Subgrid>
  );
};

const VehicleForm: FunctionComponent<VehicleFormProps> = ({ vehicle, send, forceValidation, errors, shipNames }) => {
  const sbVehicles = hooks.useStoryblokComponents('products/vehicles');

  const [sbForm, formRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.vehicle_info_form',
  });

  const onFail = (key: string) =>
    send({
      type: 'EDIT_VEHICLE',
      data: { id: vehicle.id, info: { [key]: '' } },
    });

  const onReady = (key: string, val: string) =>
    send({
      type: 'EDIT_VEHICLE',
      data: { id: vehicle.id, info: { [key]: val } },
    });

  const input = {
    registrationId: {
      ...useInputGroupState({
        label: sbForm?.content.registration,
        errorMsg: errors[API.InfoError.REGNO] || 'Invalid registration',
        type: vehicle.type as API.VehicleType,
        validationSchema: vehicleInfoSchema,
        htmlFor: `${vehicle.id}-registrationId`,
        onReady,
        onFail,
        forceValidation,
        key: 'registrationId',
        initial: vehicle.registrationId ? vehicle.registrationId : undefined,
      }),
    },
  };

  const vehicleStr = (type: API.VehicleType) => {
    // RES-158 Smeralda ship can hold shorter ships than other ships. Check if any of the ships
    // in sailings has specific dimensions defined in storyblok and if has use that instead of
    // default vehicle definition

    const shipSpecificInfo = shipNames
      .map((shipName) =>
        sbVehicles[`${type}-${shipName?.toLocaleLowerCase()}`]
          ? sbVehicles[`${type}-${shipName?.toLocaleLowerCase()}`].content
          : undefined
      )
      .filter((info) => info !== undefined);

    const info = shipSpecificInfo.length > 0 ? shipSpecificInfo[0] : sbVehicles[type]?.content;

    return info?.component === 'vehicle_with_dimensions'
      ? formatString(sbForm?.content.vehicle_with_dimensions_title, info.length, info.height)
      : info?.component === 'vehicle_with_name'
      ? info.name
      : type;
  };

  return (
    <Subgrid variant="dense" ref={formRef}>
      <H3 gridSpan={2}>{vehicleStr(vehicle.type)}</H3>
      <InputGroup gridSpan={2} {...input.registrationId} />
    </Subgrid>
  );
};

const PassengerInfoView: FunctionComponent<PassengerInfoProps & CatalogFooterPassedProps> = ({
  context,
  send,
  ...footerProps
}) => {
  const [sbCountryDefaults] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.country_defaults',
  });

  const [sbPassengersTitle, passengersTitleRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.passengers_title',
  });

  const [sbVehiclesTitle, vehiclesTitleRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.vehicles_title',
  });
  const [sbForm, formRef] = hooks.useStoryblokComponent<HTMLDivElement>({
    path: 'info.vehicle_info_form',
  });

  const sbInfoErrorCodes = hooks.useStoryblokDatasource('info-error-codes');
  const sbSummaryTags = hooks.useStoryblokDatasource('summary-tags');

  const sbAccommodation = hooks.useStoryblokComponents('products/accommodations');
  const sbCountryCodes = hooks.useStoryblokComponents('components/country-codes');
  const countryValues = Object.values(sbCountryCodes).sort((a, b) => a.content.label.localeCompare(b.content.label));

  const defaultCountryValues = sbCountryDefaults?.content.countries.map((uuid: string) =>
    countryValues.find((country) => country.uuid === uuid)
  );

  const countryLists = [
    { label: sbCountryDefaults?.content.defaults_label, list: defaultCountryValues },
    { label: sbCountryDefaults?.content.all_label, list: countryValues },
  ];

  const initialCountry = defaultCountryValues && defaultCountryValues[0]?.content?.value;
  const { agreement, catalogs, errors, passengers, searchParams, vehicles } = context;

  const isStarClubAvailableForPassenger = (passenger: API.ExtendedPassenger, searchParams: API.SearchParams): boolean =>
    passenger.type === API.PassengerType.ADULT && searchParams.starclub;

  const pageError = errors.find((e) => e.source === 'info');

  if (pageError) {
    const firstError = document.getElementById(`${pageError.id}-${pageError.field}`);

    if (firstError) {
      if (firstError.tagName === 'DIV') {
        const firstErrorChild = firstError.querySelector('input, select') as HTMLElement;
        firstErrorChild && firstErrorChild.focus();
        firstErrorChild && firstErrorChild.scrollIntoView();
      } else {
        firstError.focus();
        firstError.scrollIntoView();
      }
    }
  }
  const selectedSailings = getSelectedSailings(catalogs);
  const selectedAccommodations = getAccommodations(selectedSailings);
  const accommodationProducts = getAccommodationsAsProducts(selectedSailings);
  const firstDepartureDateTime = List.head(selectedSailings).mapOrDefault(
    ({ sailing }) => parseISO(`${sailing.departureDate} ${sailing.departureTime}`),
    new Date()
  );
  const selectedShipNames = selectedSailings.map((_) => _.sailing.shipName);
  return (
    <>
      <Sticky.Scroll>
        <Theme.Alternative>
          <Container variant="form">
            <H2 ref={passengersTitleRef}>{sbPassengersTitle?.content.title}</H2>

            <Notification
              legs={catalogs.reduce(
                (legs, catalog) =>
                  getSelected(catalog).mapOrDefault(
                    (selected) => legs.concat([[selected.departurePort as API.Port, selected.arrivalPort as API.Port]]),
                    legs
                  ),
                [] as [API.Port, API.Port][]
              )}
              storyblokPath="info.notification-list"
            />

            {passengers.map((passenger, i) =>
              API.isPassengerInput(passenger) ? (
                <React.Fragment key={passenger.id}>
                  <PassengerForm
                    {...{
                      passenger,
                      send,
                      countryLists,
                      initialCountry,
                      firstDepartureDateTime,
                      forceValidation: pageError ? true : false,
                      starclub: isStarClubAvailableForPassenger(passenger, searchParams),
                      errors: sbInfoErrorCodes,
                      tags: createTags(
                        selectedSailings,
                        context.searchParams.type,
                        passenger,
                        sbAccommodation,
                        sbSummaryTags[TripTagTypes.NOCABIN]
                      ),
                      requireGender: inGenderSpecific(passenger, selectedAccommodations, accommodationProducts),
                      selectedOnboards: catalogs.flatMap((catalog) => {
                        if (catalog.selected && catalog.selected.onboards) {
                          return Object.values(catalog.selected.onboards).filter((onboard: API.ExtendedOnboard) =>
                            onboard.passengerIndex?.includes(passenger.id)
                          );
                        }
                        return [];
                      }),
                      selectedSailings,
                      showTravelDocumentNumberField: isTravelDocumentNumberFieldVisible(selectedSailings),
                    }}
                  />
                  {passenger.reserver && (
                    <InfoForm
                      {...{
                        passenger,
                        send,
                        countryLists,
                        initialCountry,
                        firstDepartureDateTime,
                        forceValidation: pageError ? true : false,
                        privacyError: 'Please accept privacy policies',
                        errors: sbInfoErrorCodes,
                      }}
                    />
                  )}
                </React.Fragment>
              ) : null
            )}
            {(vehicles.length === 0 || vehicles.some((v) => v.type !== API.VehicleType.BCY)) && (
              <H2 ref={vehiclesTitleRef}>{sbVehiclesTitle?.content.title}</H2>
            )}
            {vehicles.length === 0 && <P ref={formRef}>{sbForm?.content.no_vehicles_title}</P>}
            {vehicles.map(
              (vehicle) =>
                vehicle.type !== API.VehicleType.BCY && (
                  <VehicleForm
                    key={vehicle.id}
                    {...{
                      vehicle,
                      send,
                      forceValidation: pageError ? true : false,
                      errors: sbInfoErrorCodes,
                      shipNames: selectedShipNames,
                    }}
                  />
                )
            )}

            {pageError && sbInfoErrorCodes[pageError.msg] && (
              <Alert severity="error">{sbInfoErrorCodes[pageError.msg]}</Alert>
            )}
          </Container>
        </Theme.Alternative>
      </Sticky.Scroll>
      <CatalogFooter
        {...footerProps}
        summary={{
          agreement,
          sailings: getSelectedSailings(catalogs),
        }}
      />
    </>
  );
};

export default PassengerInfoView;
