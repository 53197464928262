/*
restrictions:
  returnDate requires startDate
  Dates in correct format
  Passengers are limited to 9
  Vehicles require passengers

example:
?type=RETURN
&departurePort=FIHEL
&arrivalPort=DETRV
&startDate=2020-09-12
&returnDate=2020-09-15
&ADULT=1
&JUNIOR=1
&CHILD=1
&INFANT=1
&pets=1
&vehicles=CAR,CAR
&offerCode=ABCD
&starclub=true
&currency=SEK

example MULTILEG:
?type=MULTILEG
&departurePort=FIHEL
&arrivalPort=DETRV
&departurePortSecondLeg=DETRV
&arrivalPortSecondLeg=SEMMA
&startDate=2024-12-15
&startDateSecondLeg=2024-12-20
&ADULT=1
&JUNIOR=1
&CHILD=1
&INFANT=1
&pets=1
&vehicles=CAR,CAR
&offerCode=ABCD
&starclub=true
&currency=SEK

example MULTILEG:
?type=MULTILEG
&departurePort=FIHEL
&arrivalPort=DETRV
&departurePortSecondLeg=DETRV
&arrivalPortSecondLeg=SEMMA
&startDate=2025-01-01
&startDateSecondLeg=2025-01-03
*/

import { SearchParams, TripType, Form, Port, VehicleType, Passengers, Currency } from './types';
import { parseISO, format } from 'date-fns';
import { startToEndDate, formDates } from '../components/search/common';
import { SelectedListType } from '../design-system/components/TreeSelect/SelectedList';
import { maxPassengers } from './constants';
import { hasEnoughDriversAsCodes } from './utils/vehicleUtils';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type PartialExcept<T, K extends keyof T> = RecursivePartial<T> & Pick<Partial<T>, K>;

export type LinkedPartial = PartialExcept<SearchParams, 'passengers' | 'vehicles' | 'legMeta'>;

export const searchToParams = (searchQuery: string, range: number = 3): LinkedPartial => {
  const query = parseQuery(searchQuery);

  const type = query['type'] && query['type'] in TripType ? query['type'] : undefined;
  const forms = createForms(query, range);
  const petCount = query['pets'] && !isNaN(parseInt(query['pets'])) ? parseInt(query['pets']) : undefined;
  const offerCode = query['offerCode'] && typeof query['offerCode'] === 'string' ? query['offerCode'] : undefined;
  const starclub = query['starclub'] ? query['starclub'] === 'true' : undefined;
  const passengers = createPassengers(query);
  const vehicles = passengers ? createVehicles(query, passengers) : undefined;
  const currency = query['currency'] && query['currency'] in Currency ? query['currency'] : undefined;

  return {
    type,
    forms,
    petCount,
    offerCode,
    starclub,
    passengers,
    vehicles,
    currency,
  };
};

export const combineForms = (overwriting: RecursivePartial<Form | undefined>[], to: Form[]): Form[] => {
  return to.map((form) => {
    const match = overwriting.find((f) => f?.index === form.index);
    return match
      ? {
          ...form,
          ...match,
          selectedDate: match.selectedDate ? match.selectedDate : form.selectedDate,
          params: Object.keys(form.params).reduce((params, key) => {
            if (match.params && match.params[key as keyof Form['params']])
              params[key] = match.params[key as keyof Form['params']];
            else params[key] = form.params[key as keyof Form['params']];
            return params;
          }, {} as any),
        }
      : form;
  });
};

const parseQuery = (query: string) =>
  query
    .substring(1)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {} as any);

const createForms = (query: any, range: number): PartialExcept<Form, 'index'>[] => {
  const preStartDate = parseISO(query['startDate']);
  const startDate = !isNaN(preStartDate.getTime())
    ? preStartDate.getTime() < Date.now()
      ? new Date()
      : preStartDate
    : undefined;

  const forms = [
    {
      index: 0,
      selectedDate: startDate ? format(startDate, 'yyyy-MM-dd') : undefined,
      params: {
        departurePort: query['departurePort'] && query['departurePort'] in Port ? query['departurePort'] : undefined,
        arrivalPort: query['arrivalPort'] && query['arrivalPort'] in Port ? query['arrivalPort'] : undefined,
        ...(startDate ? formDates(startDate, range) : {}),
      },
    },
  ];

  if (query['type'] === TripType.RETURN) {
    const preReturnDate = parseISO(query['returnDate']);

    const returnDate = startDate
      ? isNaN(preReturnDate.getTime()) || preReturnDate <= startDate
        ? startToEndDate(startDate, range)
        : preReturnDate
      : undefined;

    forms.push({
      index: 1,
      selectedDate: returnDate ? format(returnDate, 'yyyy-MM-dd') : undefined,
      params: {
        departurePort: query['arrivalPort'] && query['arrivalPort'] in Port ? query['arrivalPort'] : undefined,

        arrivalPort: query['departurePort'] && query['departurePort'] in Port ? query['departurePort'] : undefined,
        ...(returnDate ? formDates(returnDate, range) : {}),
      },
    });
  }

  if (query['type'] === TripType.MULTILEG) {
    const secondLegDeparturePort =
      query['departurePortSecondLeg'] && query['departurePortSecondLeg'] in Port
        ? query['departurePortSecondLeg']
        : undefined;

    const secondLegArrivalPort =
      query['arrivalPortSecondLeg'] && query['arrivalPortSecondLeg'] in Port
        ? query['arrivalPortSecondLeg']
        : undefined;

    const preSecondLegStartDate = parseISO(query['startDateSecondLeg']);
    const secondLegStartDate = startDate
      ? isNaN(preSecondLegStartDate.getTime()) || preSecondLegStartDate <= startDate
        ? startToEndDate(startDate, range)
        : preSecondLegStartDate
      : undefined;

    forms.push({
      index: 1,
      selectedDate: secondLegStartDate ? format(secondLegStartDate, 'yyyy-MM-dd') : undefined,
      params: {
        departurePort: secondLegDeparturePort,
        arrivalPort: secondLegArrivalPort,
        ...(secondLegStartDate ? formDates(secondLegStartDate, range) : {}),
      },
    });
  }

  return forms;
};

const createVehicles = (query: any, passengers: Passengers): SelectedListType | undefined =>
  query['vehicles']
    ? (query['vehicles'].split(',') as string[]).reduce((result, current: string, index: number) => {
        if (current in VehicleType && hasEnoughDriversAsCodes(current as VehicleType, passengers, result))
          result.push({ id: index, productcode: current });
        return result;
      }, [] as SelectedListType)
    : undefined;

const createPassengers = (query: any): Passengers | undefined => {
  const adult = query['ADULT'] && !isNaN(parseInt(query['ADULT'])) ? parseInt(query['ADULT']) : undefined;
  const junior = query['JUNIOR'] && !isNaN(parseInt(query['JUNIOR'])) ? parseInt(query['JUNIOR']) : undefined;
  const child = query['CHILD'] && !isNaN(parseInt(query['CHILD'])) ? parseInt(query['CHILD']) : undefined;
  const infant = query['INFANT'] && !isNaN(parseInt(query['INFANT'])) ? parseInt(query['INFANT']) : undefined;

  const passengers =
    adult || junior
      ? {
          ADULT: adult ? adult : 0,
          JUNIOR: junior ? junior : 0,
          CHILD: adult && child ? child : 0,
          INFANT: adult && infant ? infant : 0,
        }
      : undefined;
  if (passengers) {
    if (passengers.ADULT > maxPassengers) passengers.ADULT = maxPassengers;
    if (passengers.JUNIOR + passengers.ADULT > maxPassengers) passengers.JUNIOR = maxPassengers - passengers.ADULT;
    if (passengers.CHILD + passengers.ADULT + passengers.JUNIOR > maxPassengers)
      passengers.CHILD = maxPassengers - passengers.ADULT - passengers.JUNIOR;
    if (passengers.INFANT + passengers.CHILD + passengers.ADULT + passengers.JUNIOR > maxPassengers)
      passengers.INFANT = maxPassengers - passengers.ADULT - passengers.JUNIOR - passengers.CHILD;
  }

  return passengers;
};
