import { AccommodationError, PriceError, ProductError, SailingError, VehicleError } from './errors';
import * as API from '../../FinnlinesB2CBookingAPI';

export interface Catalog {
  index: number;
  sailings: ExtendedSailing[];
  selected?: SelectedSailing;
  error?: SailingError;
  fetchingPricesDone?: boolean;
}

export type CatalogError = { source: string; field?: string; msg: string; id?: number | string };
export type CatalogErrors = CatalogError[];

export type QuotesByTariff = {
  [API.Tariff.SPECIAL]: API.BookingQuote;
  [API.Tariff.STANDARD]: API.BookingQuote;
};

export type SailingsByTariff = {
  [API.Tariff.SPECIAL]: API.Sailing | null;
  [API.Tariff.STANDARD]: API.Sailing | null;
};

export const DEFAULT_TARIFF = API.Tariff.SPECIAL;

export type TariffPrice = {
  [API.Tariff.SPECIAL]: API.ChargeInfo | PriceError | null;
  [API.Tariff.STANDARD]: API.ChargeInfo | PriceError | null;
};

export type TariffPriceOrError = TariffPrice | PriceError;

/**
 * Accommodation types
 */

type AccommodationMeta = {
  id: number;
  price?: TariffPriceOrError;
};

export type ExtendedAccommodation = API.AccommodationInput & AccommodationMeta;

/**
 * Sailing types
 */
export type ExtendedSailing = API.Sailing & {
  meta?: SailingMeta;
  price?: TariffPriceOrError | null;
  vehicleError?: VehicleError;
  accommodationError?: AccommodationError;
};

export type SelectedSailing = {
  accommodations?: ExtendedAccommodation[] | AccommodationError;
  offerCode?: string;
  onboards?: ExtendedOnboard[] | PriceError;
  products: Products | ProductError;
  quote?: QuotesByTariff;
  sailingCode: string;
  tariff: API.Tariff;
  price?: TariffPriceOrError | null;
  arrivalDate?: string;
  arrivalTime?: string;
  departureDate?: string;
  departureTime?: string;
  meta?: SailingMeta;
};

export type SailingMeta = {
  initialAccommodations?: number | null;
  initialCheapestPrice?: TariffPrice | null;
  loading: boolean;
  offercodeViable?: {
    [API.Tariff.SPECIAL]: boolean;
    [API.Tariff.STANDARD]: boolean;
  };
};

/**
 * Product types
 */

type ProductInfo = {
  price?: TariffPriceOrError;
};

export type ExtendedProduct = API.Product & ProductInfo;

export type Products = {
  [API.ProductType.ACCOMMODATION]?: ExtendedProduct[];
  [API.ProductType.BIKE]?: ExtendedProduct[];
  [API.ProductType.PASSENGER]?: ExtendedProduct[];
  [API.ProductType.VEHICLE]?: ExtendedProduct[];
  [API.ProductType.PET]?: ExtendedProduct[];
} & OnboardTypes;

export type OnboardTypes = {
  [API.ProductType.ELECTRICITY]?: ExtendedProduct[];
  [API.ProductType.FOOD]?: ExtendedProduct[];
  [API.ProductType.PACKAGE]?: ExtendedProduct[];
  [API.ProductType.WIFI]?: ExtendedProduct[];
  [API.ProductType.NIGHT]?: ExtendedProduct[];
  [API.ProductType.WELLNESS]?: ExtendedProduct[];
  [API.ProductType.COUPON]?: ExtendedProduct[];
  [API.ProductType.LOUNGE]?: ExtendedProduct[];
};

export enum Onboards {
  ELECTRICITY = 'ELECTRICITY',
  FOOD = 'FOOD',
  PACKAGE = 'PACKAGE',
  WIFI = 'WIFI',
  NIGHT = 'NIGHT',
  WELLNESS = 'WELLNESS',
  LOUNGE = 'LOUNGE',
}

//Array of chargeinfo for all products of same code
export type ExtendedOnboard = API.OnboardInput & {
  price?: TariffPriceOrError;
};

/**
 * Passenger types
 */
export enum PassengerCountry {
  FI = 'FI',
  SE = 'SE',
  DE = 'DE',
  RU = 'RU',
}

export type PassengerInfo = {
  firstname?: string;
  lastname?: string;
  birth?: string;
  gender?: string;
  nationality?: string;
  starclub?: string;
  reserverInfo?: ReserverInfo;
  specialNeeds?: string;
  travelDocumentNumber?: string;
};

export type ReserverInfo = {
  address?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  countryCallCode?: string;
  phone?: string;
  email?: string;
  termsOfServiceOk?: boolean | null;
  privacyPolicyOk?: boolean | null;
  newsletterOk?: boolean | null;
};

export type ExtendedPassenger = API.PassengerInput & {
  readonly reserver: boolean;
  info?: PassengerInfo;
  price?: { [leg: number]: TariffPriceOrError };
};

export type ExtendedPet = API.PetInput & {
  price?: { [leg: number]: TariffPriceOrError };
};

export type ExtendedPassengerAndPet = ExtendedPassenger | ExtendedPet;

/**
 * Vehicle types
 */

export type VehicleInfo = {
  id: number;
  price?: { [leg: number]: TariffPriceOrError };
  assignedDriver: number;
};

export type ExtendedVehicle = API.VehicleInput & VehicleInfo;
