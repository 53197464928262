import { ErrorCode } from '../../FinnlinesB2CBookingAPI';

export enum SearchError {
  LOAD_FAIL = 'LOAD_FAIL',
  SAME_DEP_DEST = 'SAME_DEP_DEST',
  REQUIRE_VEHICLE = 'REQUIRE_VEHICLE',
  MISSING_VAL = 'MISSING_VAL',
  NOT_ENOUGH_PASSENGERS = 'NOT_ENOUGH_PASSENGERS',
  UNSPECIFIED = 'UNSPECIFIED',
  INVALID_OFFER_CODE = 'INVALID_OFFER_CODE',
}

export enum AccommodationError {
  NOT_AVAIL_ACCOMMODATIONS = 'NOT_AVAIL_ACCOMMODATIONS',
  NO_ADULTS_OR_JUNIORS = 'NO_ADULTS_OR_JUNIORS',
  NO_AVAIL_CABINS = 'NO_AVAIL_CABINS',
  NO_AVAIL_PET_CABINS = 'NO_AVAIL_PET_CABINS',
  CHILD_NO_SUPERVISOR = 'CHILD_NO_SUPERVISOR',
  PETS_NO_SUPERVISOR = 'PETS_NO_SUPERVISOR',
  NO_SPACE = 'NO_SPACE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum VehicleError {
  NOT_AVAIL_VEHICLES = 'NOT_AVAIL_VEHICLES',
  NO_VEHICLE_SPACE = 'NO_VEHICLE_SPACE',
  UNSPECIFIED = 'UNSPECIFIED',
}
export enum ProductError {
  PRODUCT_QUERY_FAIL = 'PRODUCT_QUERY_FAIL',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum SailingError {
  NO_DEPARTURES = 'NO_DEPARTURES',
  NO_AVAIL_DEPARTURES_FOR_LEG = 'NO_AVAIL_DEPARTURES_FOR_LEG',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum PriceError {
  NO_PRICE = 'NO_PRICE',
  NO_SPECIAL = 'NO_SPECIAL',
  NO_STANDARD = 'NO_STANDARD',
  SAILING_OVERLAPS = 'SAILING_OVERLAPS',
  SAILING_ONBOARDING = 'SAILING_ONBOARDING',
  OFFERCODE_NOT_VIABLE = 'OFFERCODE_NOT_VIABLE',
  OFFERCODE_NO_TARIFF = 'OFFERCODE_NO_TARIFF',
  CABIN_REQUIRED_PET = 'CABIN_REQUIRED_PET',
  NO_AVAIL_PET_CABINS = 'NO_AVAIL_PET_CABINS',
  PETS_NO_SUPERVISOR = 'PETS_NO_SUPERVISOR',
  ACCOMMODATION_NOT_AVAILABLE = 'ACCOMMODATION_NOT_AVAILABLE',
  NO_VEHICLE_SPACE = 'NO_VEHICLE_SPACE',
  VEHICLE_REQUIRED = 'VEHICLE_REQUIRED',
  BOOKING_FLOW_STOPPED = 'BOOKING_FLOW_STOPPED', // | IBE0253 - The booking flow is stopped: At least one pet cabin must be requested | Frontend has sent a QuoteBooking request without cabin despite pets are travelling. Several fixes have been done to prevent these, but these might still occur. Fix should be done to frontend, if these still come up |
  NO_PRICE_RELOAD_PRICE = 'NO_PRICE_RELOAD_PRICE',
  UNSPECIFIED = 'UNSPECIFIED',
  NO_CRUISE_AVAILABLE = 'NO_CRUISE_AVAILABLE',
  NO_PASSENGER_CAPACITY = 'NO_PASSENGER_CAPACITY',
  NOT_AVAIL_ACCOMMODATIONS = 'NOT_AVAIL_ACCOMMODATIONS',
}

export enum InfoError {
  FINAL_CHECK = 'FINAL_CHECK',
  DATA_CONSENT = 'DATA_CONSENT',
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
  BIRTH = 'BIRTH',
  STARCLUB = 'STARCLUB',
  GENDER = 'GENDER',
  NATIONALITY = 'NATIONALITY',
  TRAVEL_DOCUMENT_NUMBER = 'TRAVEL_DOCUMENT_NUMBER',
  ADDRESS = 'ADDRESS',
  POSTALCODE = 'POSTALCODE',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  REGNO = 'REGNO',
  FEMALE_GENDER = 'FEMALE_GENDER',
  MALE_GENDER = 'MALE_GENDER',
  BOTH_GENDERS = 'BOTH_GENDERS',
}

export enum PassengerError {}

export type Errors =
  | AccommodationError
  | InfoError
  | PassengerError
  | PriceError
  | ProductError
  | ErrorCode
  | SearchError
  | VehicleError;

export const Errors = {
  ...SearchError,
  ...AccommodationError,
  ...VehicleError,
  ...ProductError,
  ...ErrorCode,
  ...PriceError,
  ...InfoError,
  ...PassengerError,
};

export const ErrorValues = Object.values(Errors);

// This Nonblocking list contains error codes where user may recover the situation by clicking element concerned.
// Otherwise they are non-clickable.
export type Nonblocking = typeof Errors[keyof typeof Errors];
export const Nonblocking: Nonblocking[] = [
  PriceError.ACCOMMODATION_NOT_AVAILABLE,
  PriceError.NO_PRICE_RELOAD_PRICE,
  PriceError.SAILING_OVERLAPS,
  PriceError.SAILING_ONBOARDING,
  PriceError.UNSPECIFIED,
  ErrorCode.SAILING_NOT_FOUND,
  ErrorCode.NO_PRICE_AVAILABLE,
];

export const isError = (value: any): value is Errors =>
  typeof value === 'string' && (Object.values(Errors) as string[]).includes(value);
